import {
  createSettingsParams,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';
import calendarSettingsParams, {
  ISettingsParams,
} from '../BookingCalendar/settingsParams';
import { AlignmentOptions, LayoutOptions } from '../../types/types';

type WeeklyTimetableSettingsOverride = Pick<
  ISettingsParams,
  | 'textAlignment'
  | 'calendarLayout'
  | 'headerServiceFilterVisibility'
  | 'headerStaffFilterVisibility'
  | 'headerLocationFilterVisibility'
>;

const weeklyTimetableSettingsParams =
  createSettingsParams<WeeklyTimetableSettingsOverride>({
    calendarLayout: {
      getDefaultValue: ({ isMobile }) =>
        isMobile
          ? LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER
          : LayoutOptions.WEEKLY_TIMETABLE,
    },
    textAlignment: {
      getDefaultValue: () => AlignmentOptions.CENTER,
    },
    headerServiceFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => true,
    },
    headerStaffFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => true,
    },
    headerLocationFilterVisibility: {
      type: SettingsParamType.Boolean,
      getDefaultValue: () => false,
    },
  });

export default {
  ...calendarSettingsParams,
  ...weeklyTimetableSettingsParams,
};
